exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-tags-page-js": () => import("./../../../src/templates/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */)
}

